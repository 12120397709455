.name { grid-area: name; }
.stats { grid-area: stats; }
.story { grid-area: story; }
.like { grid-area: like;}
.timeline { grid-area: timeline; }

.grid-container {
    display: grid;
    grid-template-areas:
    'name name name name stats'
    'story story story like stats'
    'timeline timeline timeline timeline stats';
    gap: 10px;
    padding: 10px;
    margin: 3rem;
}

.main-content{
    background-color: #FFFFFF;
}

.grid-container > div {
    text-align: left;
    padding: 20px 0;
    font-size: 30px;
}
